import React from "react";
import css from './riskMitigationLevels.module.scss';
import {getImagesFluid, renderFixedHeightImageByFileName, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_risk_mitigation_levels"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const RiskMitigationLevels = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const imageHeight = 340;

    const levels = [{
        color: "#77BF3B",
        text: <>Satellite Imagery Monitoring</>
    }, {
        color: "#5F992F",
        text: <>Digital Delivery Note</>
    }, {
        color: "#4F8028",
        text: <>Log Image <br/>Fingerprint Signature</>
    }, {
        color: "#3F6620",
        text: <>Log Encrypted<br/>Code Indenting</>
    }, {
        color: "#37591C",
        text: <>Log Fingerprint Signature<br/>PLUS Encrypted Code Indenting</>
    }];

    const renderDesktopLevel = no => {
        const {color, text} = levels[no - 1];
        return (
            <div className={css.desktopLevel}>
                <div className={css.levelLabel} style={{background: color}}>LEVEL {no}</div>
                <div className={css.levelText}>{text}</div>
            </div>
        )
    }

    const renderTabletLevel = no => {
        const {color, text} = levels[no - 1];
        return (
            <div>
                <div className={css.levelLabel} style={{background: color}}>LEVEL {no}</div>
                <div className={css.levelText}>{text}</div>
            </div>
        )
    }

    const renderPhoneLevel = no => {
        const {color, text} = levels[no - 1];
        return (
            <div className={css.desktopLevel}>
                <div className={[css.levelLabel, css.phoneLevelLabel].join(' ')} style={{background: color}}>LEVEL {no}</div>
                <div className={[css.levelText, css.phoneLevelText].join(' ')}>{text}</div>
            </div>
        )
    }

    const renderDesktopLevels = () => {
        return <div className={css.levels}>
            {renderDesktopLevel(1)}
            {renderDesktopLevel(2)}
            {renderDesktopLevel(3)}
            {renderDesktopLevel(4)}
            {renderDesktopLevel(5)}
        </div>
    }

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <div className={css.title}>Risk Mitigation Levels</div>
                <div className={css.desktopWrapper}>
                    <div className={css.imageSectionWrapper}>
                        <div className={[css.imageWrapper, css.img1].join(' ')}>
                            {renderFixedHeightImageByFileName(imagesFluid, 'risk_mitigation_levels_1.png', 'risk mitigation level', imageHeight)}
                        </div>
                        <div className={[css.imageWrapper, css.img2].join(' ')}>
                            {renderFixedHeightImageByFileName(imagesFluid, 'risk_mitigation_levels_2.png', 'risk mitigation level', imageHeight)}
                        </div>
                        <div className={[css.imageWrapper, css.img3].join(' ')}>
                            {renderFixedHeightImageByFileName(imagesFluid, 'risk_mitigation_levels_3_4_5.png', 'risk mitigation level', imageHeight)}
                        </div>
                    </div>
                    {renderDesktopLevels()}
                </div>
                <div className={css.tabletWrapper}>
                    <div className={css.tabletRow}>
                        <div className={css.tabletLevel}>{renderTabletLevel(1)}</div>
                        <div className={css.tabletImage}>
                            {renderImageByFileName(imagesFluid, 'risk_mitigation_levels_1_mobile.png', 'risk mitigation level')}
                        </div>
                    </div>
                    <div className={css.tabletRow}>
                        <div className={css.tabletLevel}>{renderTabletLevel(2)}</div>
                        <div className={css.tabletImage} style={{maxWidth: 280}}>
                            {renderImageByFileName(imagesFluid, 'risk_mitigation_levels_2_mobile.png', 'risk mitigation level')}
                        </div>
                    </div>
                    <div className={css.tabletTripleRow}>
                        <div className={css.tabletTripleRowLevels}>
                            <div className={css.tabletLevel}>{renderTabletLevel(3)}</div>
                            <div className={css.tabletLevel}>{renderTabletLevel(4)}</div>
                            <div className={css.tabletLevel}>{renderTabletLevel(5)}</div>
                        </div>
                        <div className={css.tabletImage} style={{maxWidth: 280}}>
                            {renderImageByFileName(imagesFluid, 'risk_mitigation_levels_3_4_5_mobile.png', 'risk mitigation level')}
                        </div>
                    </div>
                </div>
                <div className={css.phoneWrapper}>
                    <div className={css.phoneRow}>
                        <div className={css.phoneLevel}>{renderPhoneLevel(1)}</div>
                        <div className={css.phoneImage}>
                            {renderImageByFileName(imagesFluid, 'risk_mitigation_levels_1_mobile.png', 'risk mitigation level')}
                        </div>
                    </div>
                    <div className={css.phoneRow}>
                        <div className={css.phoneLevel}>{renderPhoneLevel(2)}</div>
                        <div className={css.phoneImage} style={{maxWidth: 280}}>
                            {renderImageByFileName(imagesFluid, 'risk_mitigation_levels_2_mobile.png', 'risk mitigation level')}
                        </div>
                    </div>
                    <div className={css.phoneTripleRow}>
                        <div className={css.phoneTripleRowLevels}>
                            <div className={css.phoneLevel}>{renderPhoneLevel(3)}</div>
                            <div className={css.phoneLevel}>{renderPhoneLevel(4)}</div>
                            <div className={css.phoneLevel}>{renderPhoneLevel(5)}</div>
                        </div>
                        <div className={css.phoneImage} style={{maxWidth: 280}}>
                            {renderImageByFileName(imagesFluid, 'risk_mitigation_levels_3_4_5_mobile.png', 'risk mitigation level')}
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default RiskMitigationLevels;
