import React from 'react';
import css from './technologyPartner.module.scss';
import {getRoutes} from "../../shared/routes";
import TechnologyPartnerImage from "../../../images/sections/technologyPartner/TimberID_PEFC_TECH_PARTNER_1.svg";
import Text from "../../shared/Text/Text";

const TechnologyPartner = () => {
    const routes = getRoutes();
    const title = routes.home.sections.technologyPartner.title;

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperBg}/>
            <div className={css.content}>
                <div className={css.title}>{title}</div>
                <div className={css.bigContent}>
                    <div className={css.imageWrapper}>
                        <div className={css.image}><img src={TechnologyPartnerImage} alt="Technology Partner"/></div>
                    </div>
                    <div className={css.textWrapper}>
                        <Text>
                            <p>Our <a href={"https://pefc.org/cms/b999fadb-8521-4d4f-8d3c-4688f57fdced/news/10001145/the-timberid-supply-chain-solution-to-ensure-eudr-compliance"} style={{color: 'var(--mainGreenColor)'}}>Technology Partnership with PEFC</a> International is aiming to empower all certified organisations to meet the stringent requirements of the EUDR through the TimberID solution.</p>
                            <p>TimberID is the only timber specific SaaS platform with deep tech options solving all EUDR requirements.</p>
                            <p>Country-specific risk mitigation strategies and chain of custody transparency are embracing a sustainable and compliant future.</p>
                            <p>PEFC-certified organisations can enjoy special pricing!</p>
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechnologyPartner;
