import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './satelliteImageryDeforestationAnalysis.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import WideSection from "../../shared/WideSection/WideSection";
import ListItem from "../../shared/ListItem/ListItem";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_SatelliteImageryDeforestationAnalysis"}}) {
    nodes {
   childImageSharp {
        fluid(maxWidth: 360, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const SatelliteImageryDeforestationAnalysis = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const rightContent =
        <div className={css.right}>
            <div className={css.text}>TimberID Deforestation Report structure:</div>
            <div>
                <ListItem
                    list={[
                        'Report summary ',
                        'Satellite images sourcing - Copernicus Sentinel ',
                        'Forest maintained map, lost forest, gained forest, from 2020 to date, on full year ',
                        'Basic data about the plot, as surface area, coordinates, biomass ',
                        'Forest cover map for the year 2020 provided by the EC JRC',
                        'Tropical Tree Cover, 2020 ',
                        'Satellite image + Forest mask - annual forest loss view ',
                        '2020-2023 - Satellite image + Forest mask ',
                        'Risk of future deforestation based on historical data ',
                        'Verification of commodity plots ',
                        'Map of proximity to population centres risk',
                        'Map of flood risk, Map of fires risk, Map of farmland proximity risk',
                    ]}
                    style={{padding: '4px 0'}}
                />
            </div>
        </div>

    const img1 = renderImageByFileName(imagesFluid, 'satellite_imagery_deforestation_analysis_1.png', 'Satellite Imagery Deforestation Analysis');
    const img2 = renderImageByFileName(imagesFluid, 'satellite_imagery_deforestation_analysis_2.png', 'Satellite Imagery Deforestation Analysis');
    const img3 = renderImageByFileName(imagesFluid, 'satellite_imagery_deforestation_analysis_3.png', 'Satellite Imagery Deforestation Analysis');

    const renderImg = imgTemp => <div className={css.leftImgWrapper}>{imgTemp}</div>;

    const renderImages = () => <>
        {renderImg(img1)}
        <div className={css.break}></div>
        {renderImg(img2)}
        {renderImg(img3)}
    </>

    return (
        <WideSection
            title={'Satellite Imagery Deforestation Analysis'}
            blackBackground
            bigContent={<div className={css.bigContent}>
                <div className={css.left}>
                    {renderImages()}
                </div>
                {rightContent}
            </div>}
            smallContent={<div className={css.smallContent}>
                <div className={css.left}>
                    {renderImages()}
                </div>
                {rightContent}
            </div>}
        />
    );
};

export default SatelliteImageryDeforestationAnalysis;
