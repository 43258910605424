import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './platform.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {getRoutes} from "../../shared/routes";
import LinkButton from "../../shared/LinkButton/LinkButton";
import WideSection from "../../shared/WideSection/WideSection";
import ListItem from "../../shared/ListItem/ListItem";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_Platform"}}) {
    nodes {
   childImageSharp {
        fluid(maxWidth: 600, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Platform = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const text = <>
        TimberID solution is an enterprise-scale businesses cloud global SaaS platform.
    </>;

    const routes = getRoutes();
    const buttons = <>
        <LinkButton
            label={'Learn more'}
            route={routes.Technology}
            section={routes.Technology.sections.saas.anchor}
        />
    </>;

    const rightContent =
        <div className={css.right}>
            <div className={css.text}>Safe and effective <span className={css.textBold}>EUDR</span> execution:</div>
            <div>
                <ListItem
                    list={[
                        'Data protection, security and privacy, the selective release of data ',
                        'Identification of country/supplier/delivery risk level ',
                        'Tailor-made risk assessments for various countries ',
                        'Satellite imagery deforestation report of a forest plot ',
                        'Accumulated geolocation data ',
                        'Unique bottom-up approach that cleverly distributes the workload ',
                        'Downstream audit checklist trail functionality and report generation ',
                        'Segregation of wood with non-compliances ',
                        'Timber traceability and provenance authentication is secured by applying the Dcode marking geolocation data set ',
                        'Automated DDS (Due Diligence Statement) creation, submission and storage'
                    ]}
                    style={{padding: '3px 0'}}
                />
            </div>
        </div>

    return (
        <WideSection
            title={'SaaS Platform'}
            blackBackground
            bigContent={<div className={css.bigContent}>
                <div className={css.left}>
                    <div className={css.leftImgWrapper}>
                        {renderImageByFileName(imagesFluid, 'SaaS_1.png', 'SaaS')}
                    </div>
                    <div className={css.leftImgWrapper}>
                        {renderImageByFileName(imagesFluid, 'SaaS_4.png', 'SaaS')}
                    </div>
                    <div className={css.leftImgWrapper}>
                        {renderImageByFileName(imagesFluid, 'SaaS_2.png', 'SaaS')}
                    </div>
                    <div className={css.leftImgWrapper}>
                        {renderImageByFileName(imagesFluid, 'SaaS_3.png', 'SaaS')}
                    </div>
                </div>
                {rightContent}
            </div>}
            smallContent={<div className={css.smallContent}>
                <div>
                    <div className={css.left}>
                        <div className={css.leftImgWrapper}>
                            {renderImageByFileName(imagesFluid, 'SaaS_1.png', 'SaaS')}
                        </div>
                        <div className={css.leftImgWrapper}>
                            {renderImageByFileName(imagesFluid, 'SaaS_4.png', 'SaaS')}
                        </div>
                        <div className={css.leftImgWrapper}>
                            {renderImageByFileName(imagesFluid, 'SaaS_2.png', 'SaaS')}
                        </div>
                        <div className={css.leftImgWrapper}>
                            {renderImageByFileName(imagesFluid, 'SaaS_3.png', 'SaaS')}
                        </div>
                    </div>
                    {rightContent}
                </div>
            </div>}
            text={text}
            buttons={buttons}
        />
    );
};

export default Platform;
