import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './homeMobileApps.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {getRoutes} from "../../shared/routes";
import LinkButton from "../../shared/LinkButton/LinkButton";
import WideSection from "../../shared/WideSection/WideSection";
import TimberIdLogo from "../../../images/logos/Timber_ID_logo.svg";
import DcodeLogo from "../../../images/logos/Dcode_logo_2.svg";
import BuyAppButtons, {buyAppButtonsVersions, urlTypes} from "../../shared/BuyAppButtons/BuyAppButtons";
import ListItem from "../../shared/ListItem/ListItem";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_HomeMobileApps"}}) {
    nodes {
   childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const HomeMobileApps = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const routes = getRoutes();
    const buttons = <>
        <LinkButton
            label={'Learn more'}
            route={routes.Technology}
            section={routes.Technology.sections.mobileApps.anchor}
        />
    </>;

    const renderAppButton = (logo, alt, urlType, imgScale) => {
        return (
            <div className={css.section}>
                <div className={css.sectionContentWrapper}>
                    <img src={logo} alt={alt} style={{transform: `scale(${imgScale})`}}/>
                    <BuyAppButtons urlType={urlType} version={buyAppButtonsVersions.footer}/>
                </div>
            </div>
        )
    }

    const appButtons = <>
        {renderAppButton(TimberIdLogo, 'TimberId Logo', urlTypes.timberId, 1)}
        {renderAppButton(DcodeLogo, 'Dcode Logo', urlTypes.dcodeR, 0.8)}

    </>

    const leftContent =
        <div className={css.left}>
            <div className={css.text}><span className={css.textBold}>TimberID</span> (iOS only)</div>
            <div>
                <ListItem
                    list={['Image sourcing, record creation', 'Registration of harvesting plots', 'Species registration', 'Log measurements automation', 'Dcode verification linked with the SaaS platform', 'Dcode reader (IOS, Android)', 'Dcode encrypting data reader', 'Data export as an item list file']}/>
            </div>
        </div>

    return (
        <WideSection
            title={'Dedicated Mobile Apps'}
            bigContent={<div className={css.bigContent}>
                {leftContent}
                <div className={css.right}>
                    <div className={css.rightImgWrapper}>
                        <div className={css.imageWrapper}>
                            {renderImageByFileName(imagesFluid, 'mobile_Apps.png', 'Mobile apps')}
                        </div>
                        <div className={css.verticalLine}/>
                        <div className={css.sectionsWrapper}>
                            {appButtons}
                        </div>
                    </div>
                </div>
            </div>}
            smallContent={<div className={css.smallContent}>
                {leftContent}
                <div className={css.right}>
                    <div className={css.rightImgWrapper}>
                        <div className={css.imageWrapper}>
                            {renderImageByFileName(imagesFluid, 'mobile_Apps_m.png', 'Mobile apps')}
                        </div>
                        <div className={css.sectionsWrapper}>
                            {appButtons}
                        </div>
                    </div>
                </div>
            </div>}
            buttons={buttons}
        />
    );
};

export default HomeMobileApps;
