import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './homeSection2.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {useStaticQuery} from "gatsby";
import DataProtectionAccessLimitation
    from "../../../images/sections/homeSection2/Data-Protection-Access-Limitation.svg";
import DueDiligenceStatements from "../../../images/sections/homeSection2/Due-Diligence-Statements.svg";
import EnvironmentalSocialImpact from "../../../images/sections/homeSection2/Environmental-Social-Impact.svg";
import GeoSpatialData from "../../../images/sections/homeSection2/Geo-spatial-Data.svg";
import ProvenanceVerification from "../../../images/sections/homeSection2/Provenance-Verification.svg";
import RegulatoryComplianceDocuments from "../../../images/sections/homeSection2/Regulatory-Compliance-Documents.svg";
import RiskAssessmentMitigation from "../../../images/sections/homeSection2/Risk-Assessment-Mitigation.svg";
import InformationGathering from "../../../images/sections/homeSection2/Information-Gathering.svg";
import TimberIDPlatformLogo from "../../../images/logos/TimberID_Platform_logo.svg";

const query = graphql`
{
   fluid: allFile(filter: {sourceInstanceName: {eq: "images_homeSection2"}}) {
     nodes {
       childImageSharp {
         fluid(maxWidth: 1090, quality: 70) {
           ...GatsbyImageSharpFluid,
           originalName
         }
       }
     }
   }
 }`;

const HomeSection2 = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const renderSection = (index, imgScale = 1, imageAlt, title, image) => {
        return (
            <div className={css.section}>
                <div className={css.sectionContentWrapper}>
                    <div className={css.sectionImageWrapper}>
                        <img src={image} alt={imageAlt} style={{transform: `scale(${imgScale})`}}/>
                    </div>
                    <div className={css.sectionTitle}>
                        {title}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={css.wrapper}>
            <div className={css.gradientToTop}/>
            <div className={css.gradientToBottom}/>
            <div className={css.fullPageWidthBg}></div>
            <div className={css.cloudSolutionBgImg}>
                {renderImageByFileName(imagesFluid, 'regulation_on_deforestation.jpg', 'regulation on deforestation')}
            </div>
            <div className={css.wrapperInner}>
                <div className={css.header}>
                    <div className={css.title}>
                        &nbsp;
                    </div>
                </div>

                <div className={css.sectionsWrapper}>
                    <div className={css.section}>
                        {renderSection(1, 1, "DUE-DILIGENCE STATEMENTS", "DUE-DILIGENCE STATEMENTS", DueDiligenceStatements)}
                        {renderSection(1, 1, "REGULATORY COMPLIANCE DOCUMENTSa", "REGULATORY COMPLIANCE DOCUMENTS", RegulatoryComplianceDocuments)}
                        {renderSection(1, 1, "INFORMATION GATHERING", "INFORMATION GATHERING", InformationGathering)}
                        {renderSection(1, 1, "ENVIRONMENTAL & SOCIAL IMPACT", "ENVIRONMENTAL & SOCIAL IMPACT", EnvironmentalSocialImpact)}
                    </div>

                    <div className={css.section}>
                        <div className={css.logo}>
                            <img src={TimberIDPlatformLogo} alt={'TimberIDPlatformLogo'}/>
                        </div>
                    </div>

                    <div className={css.section}>
                        {renderSection(1, 1, "PROVENANCE VERIFICATION", "PROVENANCE VERIFICATION", ProvenanceVerification)}
                        {renderSection(1, 1, "GEO-SPATIAL DATA", "GEO-SPATIAL DATA", GeoSpatialData)}
                        {renderSection(1, 1, "DATA PROTECTION ACCESS LIMITATION", "DATA PROTECTION ACCESS LIMITATION", DataProtectionAccessLimitation)}
                        {renderSection(1, 1, "RISK ASSESSMENT/ MITIGATION", "RISK ASSESSMENT/ MITIGATION", RiskAssessmentMitigation)}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default HomeSection2;
