import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './regulations.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
import {useTranslation} from "react-i18next";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_regulations"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Regulations = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const defaultWidth = 200;
    const defaultHeight = 120;

    const renderImageBitmap = (filename, alt = '', width = defaultWidth, text = '') => {
        return <div className={css.imageWrapper} style={{width, height: defaultHeight}}>
            {renderImageByFileName(imagesFluid, filename, alt)}
            {text && <div className={css.imageText}>{text}</div>}
        </div>;
    }

    return (
        <div className={css.wrapper}>
            <div className={css.fullPageWidthBg}>
                <div className={css.cloudSolutionBgImg}>
                    {renderImageByFileName(imagesFluid, 'Quote_bg.jpg', 'Quote_bg.jpg')}
                </div>
            </div>
            <div className={css.wrapperGradient}/>
            <div className={css.wrapperInner}>
                <div className={css.title}>
                    The TimberID complete solution meets and solves all EUDR objectives, bringing digitalisation in
                    sustainable forest management.
                </div>
                <div className={css.imageSectionWrapper}>
                    {renderImageBitmap('Satelite_Regulations.png', 'satelite', 220)}
                    {renderImageBitmap('sattelite_sourced_data.png', 'sattelite_sourced_data', 215, "Sattelite sourced data")}
                    {renderImageBitmap('ground_sourced_data.png', 'map', 215, "Ground sourced data")}
                </div>
                <div className={css.text}>
                    <span className={css.boldText}>TimberID</span> Authentication is a unique & patented risk mitigation
                    solution. <br/> <br/>
                    Digitalisation facilitates sustainable practices, eliminates <span
                    className={css.boldText}>EUDR</span> risks, and positively impacts E&S
                    forestry stakeholders.
                </div>
            </div>
        </div>
    )
};

export default Regulations;
