import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './partnersNetwork.module.scss';
import AwsLogo from "../../../images/sections/partnersNetwork/aws_logo_poweredby.svg";
import NvidiaLogo from "../../../images/sections/partnersNetwork/nvidia_inception_program_badge.svg";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";

 const query = graphql`
 {
   fluid: allFile(filter: {sourceInstanceName: {eq: "images_common"}}) {
     nodes {
       childImageSharp {
         fluid(maxWidth: 1090, quality: 70) {
           ...GatsbyImageSharpFluid,
           originalName
         }
       }
     }
   }
 }`;

const PartnersNetwork = () => {
  const { t } = useTranslation();
  const imagesFluid = getImagesFluid(useStaticQuery(query));

    const renderSection = (index, imageAlt, image, link) => {
        return (
            <div className={[css.section, css[`section${index}`]].join(' ')}>
                <div className={css.sectionImageWrapper}>
                    <a href={link} target="_blank"><img src={image} alt={imageAlt}/></a>
                </div>
            </div>
        );
    };

  return (
    <div className={css.wrapper}>
        <div className={css.fullPageWidthBg}></div>
        {/*<div className={css.cloudSolutionBgImg} style={{ backgroundImage: `url("${BgImg}")`}}/>*/}
        <div className={css.cloudSolutionBgImg}>
           {renderImageByFileName(imagesFluid, 'Proud_member_bg.jpg', 'Proud_member_bg.jpg')}
        </div>
        <div className={css.wrapperInner}>
          <div className={css.header}>
            <div className={css.title}>
                Proud member <br />of <br/>Partners Network
            </div>
           </div>

                <div className={css.sectionsWrapper}>
                    {renderSection(1, "nvidia logo", NvidiaLogo, "https://www.nvidia.com/en-au/deep-learning-ai/startups/")}
                    {renderSection(2, "aws logo", AwsLogo, "https://aws.amazon.com/")}
                </div>
            </div>
        </div>
    );
};

export default PartnersNetwork;
