import React from "react";
import css from './innovations.module.scss';
import TamperProofUnfakeableData from "../../../images/sections/innovations/Tamper-proof.svg";
import EcoFriendlyPprocessStability from "../../../images/sections/innovations/Eco-friendly-process-stability.svg";
import EUDeforestationRegulationCompliant
    from "../../../images/sections/innovations/EU-Deforestation-Regulation-compliant.svg";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_marking_by_dcode"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 700, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Innovations = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const renderSection = (index, imgScale = 1, imageAlt, title, text, image, doubleSize = false) => {
        return (
            <div className={css.section}>
                <div className={css.sectionContentWrapper}>
                    <div className={css.sectionImageWrapper}>
                        <img src={image} alt={imageAlt} style={{transform: `scale(${imgScale})`}}/>
                    </div>
                    <div className={css.sectionTitle}>
                        {title}
                    </div>
                    <div className={css.sectionText}>
                        {text}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <div className={css.title}>Marking Based On Dcode</div>
                <div className={css.imageSectionWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'marking_by_dcode_alphanumeric.png', 'marking based on dcode alphanumeric')}
                    </div>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'marking_by_dcode_l.png', 'marking based on dcode l')}
                    </div>
                </div>
                <div className={css.sectionsWrapper}>
                    {renderSection(1, 1, "Tamper Proof Unfakeable Data", "TAMPER-PROOF, UNFAKEABLE DATA", "Encrypting satellite data and delivering - 100% authentication.", TamperProofUnfakeableData)}
                    {renderSection(2, 1, "Eco Friendly Pprocess Stability", "ECO-FRIENDLY PROCESS STABILITY", "Elimination of any consumables.", EcoFriendlyPprocessStability)}
                    {renderSection(3, 1, "EU Deforestation Regulation Compliant", "EU DEFORESTATION REGULATION COMPLIANT", "Provenance traceability to the source.", EUDeforestationRegulationCompliant)}
                </div>
            </div>
        </div>
    );
};

export default Innovations;
